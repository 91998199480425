export function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
}

export const isAccessible = (location?.search || '').indexOf('accessibility') >= 0;

export function createRange(node: Node, targetPosition: number) {
  const range = document.createRange();
  range.selectNode(node);

  let pos = 0;
  const stack = [node];
  while (stack.length > 0) {
    const current = stack.pop();

    if (current.nodeType === Node.TEXT_NODE) {
      const len = current.textContent.length;
      if (pos + len >= targetPosition) {
        range.setEnd(current, targetPosition - pos);
        range.setStart(current, targetPosition - pos);
        return range;
      }
      pos += len;
    } else if (current.childNodes && current.childNodes.length > 0) {
      for (let i = current.childNodes.length - 1; i >= 0; i--) {
        stack.push(current.childNodes[i]);
      }
    }
  }

  // The target position is greater than the
  // length of the contenteditable element.
  range.setEnd(node, node.childNodes.length);
  range.setStart(node, node.childNodes.length);
  return range;
}

export function setCurrentCursorPosition(chars: number, element: HTMLElement) {
  const range = createRange(element, chars);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

export function getCurrentCursorPosition(parentNode: Node) {
  const selection = window.getSelection();

  if (!selection?.rangeCount) {
    return 0;
  }

  const range = selection.getRangeAt(0);
  const clonedRange = range.cloneRange();
  clonedRange.selectNodeContents(parentNode);
  clonedRange.setEnd(range.endContainer, range.endOffset);

  return clonedRange.toString().length;
}
